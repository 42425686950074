<template>
  <section class="content">
    <table class="table table-hover" ref="tblterlambat" id="example">
      <thead>
        <tr>
          <th>TANGGAL</th>
          <th>STAFF</th>
          <th>JAM MASUK</th>
          <th>TERLAMBAT (MENIT)</th>
          <th>KETERANGAN</th>
          <th>STATUS</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title"></h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
                <b>Please correct the following error(s):</b>
                <ul>
                  <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                </ul>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              </p>
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">TANGGAL</label>
                  <datepicker v-model="form.tanggal" id="tanggal" :options="{autoclose: true}" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">WAKTU</label>
                  <div class="row">
                    <timepicker
                      class="col-md-5 ml-2"
                      placeholder="Jam Masuk"
                      v-model="form.jam_mulai"
                    />
                  </div>
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">STAFF</label>
                    <v-select :options="optStaff" v-model="form.staff_id" :reduce="opt => opt.id" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">KETERANGAN</label>
                  <textarea
                    class="form-control"
                    id="keterangan"
                    rows="3"
                    v-model="form.keterangan"
                    placeholder="Tuliskan keterangan"
                  ></textarea>
                </div>
                <div class="col-md-6 form-group">
                    <label for="inputFile">BUKTI</label>
                    <div class="custom-file">
                        <input type="file" class="custom-file-input inputfile" id="inputFile"  name="file" @change="uploadFoto(this)">
                        <label class="custom-file-label" for="inputFile">Silahkan upload</label>
                    </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import datepicker from "@/components/Datepicker";
import timepicker from "@/components/Timepicker";
import moment from "moment";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "",
  data() {
    return {
      errors: [],
      optStaff: [],
      method: "",
      roles: "",
      formTitle: "Tambah",
      form: {
        tanggal: moment().format("DD/MM/YYYY"),
        jam_mulai: moment().format("HH:mm"),
        keterangan: "",
        jam_selesai: "",
        img_code: "",
        staff_id: "",
      },
      form_data: "",
      keterangan: "",
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;

    authFetch('/management/log_buku/staff')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
            this.optStaff = js.data;
    });
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
    submitForm: function (ev) {
      const e = this.$refs;
      var file_data = $('#inputFile').prop('files');
      if (file_data.length == 0) {
          Swal.fire({
              icon: 'info',
              title: 'Informasi',
              text: 'Mohon upload bukti!'
          })
          return false;
      } else {
        this.doUpload()
          .then((res) => {
            if (res.status === 201) {
            } else if (res.status === 400) {
            }

            return res.json();
          })
          .then((js) => {
            console.log("result");
            console.log(js);
            this.errors = [];
            if (!js.status) {
              console.log(js.details);

              for (var key in js.details) {
                if (js.details.hasOwnProperty(key)) {
                  this.errors.push(js.details[key]);
                }
              }

              return;
            } else {
              var self = this;
              const e = this.$refs;
              self.form.img_code = js.message[0].id;

              var data = Object.keys(this.form)
                .map(
                  (key) =>
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(this.form[key])
                )
                .join("&");
              var urlSubmit = "/management/keterlambatan/allstaff";
              if (this.method == "PUT")
                urlSubmit = "/management/keterlambatan/allstaff/" + this.form.id;

              authFetch(urlSubmit, {
                method: this.method,
                body: data,
              })
                .then((res) => {
                  if (res.status === 201) {
                  } else if (res.status === 400) {
                  }
                  return res.json();
                })
                .then((js) => {
                  this.errors = [];
                  if (!js.success) {
                    console.log(js.details);

                    for (var key in js.details) {
                      if (js.details.hasOwnProperty(key)) {
                        this.errors.push(js.details[key]);
                      }
                    }

                    return;
                  }
                  this.table.api().ajax.reload();
                  $(e.formDialog).modal("hide");
                });
            }
          });
      }
      ev.preventDefault();
    },
    uploadFoto: function (file) {
      var file_data = $("#inputFile").prop("files");
      var form_data = new FormData();
      var fileInput = $("#inputFile")[0];
      $.each(fileInput.files, function (k, file) {
        form_data.append("file", file);
      });
      form_data.append("subdir", "/keterlambatan/");
      form_data.append("id", this.form.id);
      this.form_data = form_data;
      return false;
    },
    doUpload: function () {
      return authFetch("/management/keterlambatan/upload_data", {
        method: "POST",
        body: this.form_data,
        headers: {
          "Content-Type": null,
        },
      });
    },
  },
  components: {
    datepicker,
    timepicker,
    datePicker,
    vSelect,
  },
  mounted() {
    const e = this.$refs;
    let self = this;

    // function format ( d ) {
    //   // `d` is the original data object for the row
    //   return `
    //   <p style="width:7rem; margin-left:auto; margin-right:90px;" class="text-wrap text-right">${d.keterangan}</p>
    //   `;
    // }

    this.table = createTable(e.tblterlambat, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/management/keterlambatan/allstaff",
      serverSide: true,
      columns: [
        { data: "tanggal" },
        { data: "staff", width: "70px" },
        { data: "jam_mulai", width: "20px" },
        { data: "terlambat", width: "20px",
            render: function (data, type, row, meta) {
              return '-';
            }, },
        { data: "keterangan" },
        // {
        //             className:      'details-control',
        //             orderable:      false,
        //             data:           null,
        //             defaultContent: `
        //             <div class="text-right">
        //             <i class="fas fa-plus-circle fa-2x"></i>
        //             </div>
        //             `
        // },
        { data: "status", width: "20px",
          render: function (data, type, row, meta) {
            return data == 0 ? '<span class="badge badge-secondary">DRAFT</span>' : '<span class="badge badge-success">DISETUJUI</span>';
          } },
      ],
      filter: false,
      rowCallback: function (row, data) {
        var start_time = moment(data.normal_date+'T'+'08:00+07');
        var end_time = moment(data.normal_date+'T'+data.jam_mulai);
        var duration = moment.duration(start_time.diff(data.jam_mulai, 'hours', true));
        if(data.jam_mulai)$('td:eq(2)', row).html(end_time.format('HH:mm'));
        if(data.jam_mulai)$('td:eq(3)', row).html(Math.abs(start_time.diff(end_time, 'minutes'))+' Menit');
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
        self.form= {
          tanggal: moment().format("DD/MM/YYYY"),
          jam_mulai: moment().format("HH:mm"),
          keterangan: "",
          jam_selesai: "",
          img_code: "",
          staff_id: "",
        },
          self.form_data = "";
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Form";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          self.form.jam_mulai = moment(self.form.normal_date+'T'+self.form.jam_mulai).format('HH:mm');
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Form";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          // self.form = evt.data;
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/management/keterlambatan/allstaff/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
    });
  },
};
</script>
<style scoped>
/* td.details-control {
  font-weight: 900; content: "\f055";
  cursor: pointer;
}

tr.shown td.details-control {
  font-weight: 900; content: "\f056";
  cursor: pointer;
} */
</style>
